<template>
  <div>
    <b-form method="post" :action="endpoint"  @submit="submit">
      <b-input-group prepend="No #" class="mt-3">
        <b-form-input id="voucher_number" v-model="form.voucher_number" />
        <b-input-group-append>
          <b-button type="submit" variant="primary" :disabled="form.voucher_number === ''">Search</b-button>
        </b-input-group-append>
        <vue-recaptcha
          v-if="withCaptcha"
          ref="invisibleRecaptcha"
          size="invisible"
          sitekey="6Leu6rIUAAAAADz_4ChiN-4772HGpe4aeTekOWP2"
          :load-recaptcha-script="true"
          @verify="onVerifyRecaptcha"
          @expired="onRecaptchaExpired"
        />
      </b-input-group>
    </b-form>
    <b-card v-if="showScanner">
      <b-card-title class="text-center">
        Scan by QRCode
        <b-button variant="link" @click="showScanner = false">(close)</b-button>
      </b-card-title>
      <qrcode-scanner
        @decode="onDecode"
      />
    </b-card>
    <div v-else class="text-center mb-3 mt-3">
      <b-button variant="primary" @click="showScanner = true">
        Scan by QRCode
      </b-button>
    </div>
    <div v-if="loading" class="text-center">
      <b-spinner label="Loading" />
    </div>
    <b-alert :show="!!error" variant="danger">
      {{ error }}
    </b-alert>
    <b-card v-if="!loading && showDetails && !!voucher_unit">
      <b-card class="card card-timeline card-plain">
        <ul class="timeline timeline-simple">
          <li class="timeline-inverted">
            <div class="timeline-badge danger">
              <i class="nc-icon nc-single-copy-04"></i>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <span class="badge badge-pill badge-danger">VOUCHER DETAIL</span>
              </div>
              <div class="timeline-body">
                <ul>
                  <li><strong> {{ voucher_unit.voucher_number }} </strong></li>
                  <li>nominal : Rp {{ voucher_unit.nominal_formatted }},- </li>
                  <li>expired : {{ voucher_unit.expired }} </li>
                  <li>status : {{ voucherUnitStatus }} </li>
                  <li v-if="showFull">is active? <strong>{{ voucher_unit.is_active ? "yes" : "no"}} </strong></li>
                </ul>
              </div>
            </div>
          </li>
          <li v-if="voucher_unit.history" class="timeline-inverted">
            <div class="timeline-badge secondary">
              <i class="nc-icon nc-calendar-60"></i>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <span class="badge badge-pill badge-secondary">VOUCHER LIFECYCLE</span>
              </div>
              <div class="timeline-body">
                <ul>
                  <li 
                    v-for="history in voucher_unit.history"
                    :key="history.label"
                  >
                    <strong>{{ history.label }} :</strong>
                    {{ toLocalTime(history.timestamp) }}
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li class="timeline-inverted">
            <div class="timeline-badge success">
              <i class="nc-icon nc-check-2"></i>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <span class="badge badge-pill badge-success">EXCHANGE PRODUCTS</span>
              </div>
              <div class="timeline-body">
                <ul>
                  <li v-if="voucher_unit.voucher_theme.pertalite == true">Pertalite</li>
                  <li v-if="voucher_unit.voucher_theme.pertamax == true">Pertamax</li>
                  <li v-if="voucher_unit.voucher_theme.turbo == true">Pertamax Turbo</li>
                  <li v-if="voucher_unit.voucher_theme.dexlite == true">Dexlite</li>
                  <li v-if="voucher_unit.voucher_theme.dex == true">Pertamina DEX</li>
                  <li v-if="voucher_unit.voucher_theme.all_variants == true">All (Non Fuel)</li>
                </ul>
              </div>
            </div>
          </li>
          <li class="timeline-inverted">
            <div class="timeline-badge info">
              <i class="nc-icon nc-credit-card"></i>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <span class="badge badge-pill badge-info">EXCHANGE OUTLETS</span>
              </div>
              <div class="timeline-body">
                <strong v-if="isAllOutlet">
                  ALL Outlet
                </strong>
                <ul v-else>
                  <li
                    v-for="outlet in outlets"
                    :key="outlet.id"
                  >
                    {{ outlet.name_with_identity }}
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import axios from 'axios'
import moment from 'moment'
import QrcodeScanner  from './qrcode-scanner'

export default {
  props: {
    endpoint: {
      type: String,
      required: true
    },
    csrfToken: {
      type: String,
      required: true
    },
    showDetails: {
      type: Boolean,
      default: true
    },
    showFull: {
      type: Boolean,
      default: true
    },
    withCaptcha: {
      type: Boolean,
      default: false
    },
    transaction: {
      type: Boolean,
      default: false
    },
    claim: {
      type: Boolean,
      default: false
    },
    voucherNumber: {
      type: String,
      default: ''
    }
  },
  components: {
    VueRecaptcha,
    QrcodeScanner
  },
  data() {
    return {
      form: {
        voucher_number: ''
      },
      showScanner: true,
      voucher_unit: null,
      error: null,
      loading: false,
      recaptchaResponse: null
    }
  },
  mounted() {
    this.form = {
      ...this.form,
      voucher_number: this.voucherNumber
    }
  },
  methods: {
    onDecode(voucher_number) {
      if (voucher_number === '') {
        return false
      }

      this.showScanner = false
      const matches = (voucher_number || '').match(/voucher_number=(.*)$/)
      if (matches && matches.length > 1) {
        this.form.voucher_number = matches[1]
      } else {
        this.form.voucher_number = voucher_number
      }
      this.submit()
    },
    async submit(evt) {
      if (evt && typeof evt.preventDefault === 'function') {
        evt.preventDefault()
        if (this.withCaptcha && !this.recaptchaResponse) {
          this.$refs.invisibleRecaptcha.execute()
          return true
        }
      }
      this.check()
    },
    onVerifyRecaptcha(response) {
      this.recaptchaResponse = response
      this.check()
    },
    onRecaptchaExpired() {
      this.recaptchaResponse = null
    },
    async check() {
      this.loading = true
      this.error = null
      this.voucher_unit = null
      try {
        this.$emit('before-check')
        const payload = {
          voucher_number: this.form.voucher_number,
          transaction: this.transaction ? 1 : 0,
          claim: this.claim ? 1 : 0
        }
        if (this.withCaptcha) {
          payload['g-recaptcha-response'] = this.recaptchaResponse
        }
        const res = await axios.post(
          this.endpoint,
          payload,
          {
            headers: {
              Accept: 'application/json',
              'X-CSRF-Token': this.csrfToken
            }
          }
        )
        const { error } = res.data || {}
        if (error) {
          this.error = error
        } else {
          this.voucher_unit = res.data
          if (!this.voucher_unit) {
            this.error = this.transaction
              ? 'Voucher isn\'t available'
              : 'Voucher not found'
          }
        }
        this.$emit('check', this.voucher_unit)
      } catch (err) {
        this.error = err
      }
      if (this.withCaptcha && this.recaptchaResponse) {
        this.recaptchaResponse = null
        this.$refs.invisibleRecaptcha.reset()
      }
      this.loading = false
    },
    toLocalTime(date) {
      return moment(date).format('DD/MM/YYYY HH:mm')
    }
  },
  computed: {
    voucherUnitStatus() {
      if (!this.voucher_unit) {
        return ''
      }

      const { status, is_expired } = this.voucher_unit

      if (this.showFull) {
        return status
      } else {
        if (is_expired) {
          return 'expired'
        }
        switch (status) {
          case 'created': return 'not ready to use'
          case 'issued' : return 'ready to use'
          case 'used' :
          case 'claimed' :
          case 'paid' :
            return 'already used'
        }
      }
      return ''
    },
    isAllOutlet() {
      return !this.voucher_unit || !this.voucher_unit.voucher_theme ||
        !this.voucher_unit.voucher_theme.voucher_theme_outlets ||
        this.voucher_unit.voucher_theme.voucher_theme_outlets.length === 0
    },
    outlets() {
      const outs = (this.voucher_unit && this.voucher_unit.voucher_theme && this.voucher_unit.voucher_theme.voucher_theme_outlets) || []

      return outs.map(o => o.outlet)
    }
  }
}
</script>

<style>
#voucher-checker .wrapper { height: auto; margin-top: 1rem; margin-bottom: 1rem; }
</style>
