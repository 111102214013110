/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Vue from 'vue'
import TurbolinksAdapter from 'vue-turbolinks'
import BootstrapVue from 'bootstrap-vue'
import VueCurrencyFilter from 'vue-currency-filter'
import OutletTransaction from '../outlet-transaction'
import VoucherChecker from '../voucher-checker'
import SellingOut from '../selling-out'
import VoucherClaim from '../voucher-claim'
import VoucherPaid from '../voucher-paid'

import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../application.css'

Vue.use(TurbolinksAdapter)
Vue.use(BootstrapVue)
Vue.use(VueCurrencyFilter, {
  symbol : 'Rp.',
  thousandsSeparator: '.',
  fractionCount: 0,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true
})

function loadOutletTransaction () {
  var el = document.getElementById('outlet-transaction')
  if (el) {
    console.log('outlet transaction loaded')
    new Vue({
      el,
      components: { OutletTransaction },
    })
  }
}

function loadVoucherChecker () {
  var el = document.getElementById('voucher-checker')
  if (el) {
    console.log('voucher checker loaded')
    new Vue({
      el,
      components: { VoucherChecker },
    })
  }
}

function loadSellingOut () {
  var el = document.getElementById('selling-out')
  if (el) {
    console.log('selling out loaded')
    new Vue({
      el,
      components: { SellingOut },
    })
  }
}

function loadVoucherPaid () {
  var el = document.getElementById('voucher-paid')
  if (el) {
    console.log('voucher paid out loaded')
    new Vue({
      el,
      components: { VoucherPaid },
    })
  }
}

function loadVoucherClaim () {
  var el = document.getElementById('voucher-claim')
  if (el) {
    console.log('voucher claim out loaded')
    new Vue({
      el,
      components: { VoucherClaim },
    })
  }
}

document.addEventListener('turbolinks:load', () => {
  loadOutletTransaction()
  loadVoucherChecker()
  loadSellingOut()
  loadVoucherPaid()
  loadVoucherClaim()
})
