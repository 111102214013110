<template>
  <div>
    <b-form :action="endpoint"  @submit="submit">
      <b-row>
        <b-col xs="12" md="6">
          <b-form-group label="To Outlet" label-for="outletId">
            <b-form-select id="outletId" class="form-control" :value="form.outletId" :options="outletOptions" @change="changeOutlet" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-card title="Select Claimed Vouchers">
            <b-table
              responsive
              :busy="!vouchers"
              :fields="voucherFields"
              :items="vouchers"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template slot="HEAD_id" slot-scope="data">
                <b-form-checkbox
                  v-model="form.selectAll"
                  :disabled="!vouchers || vouchers.length === 0"
                  @change="selectAllVouchers"
                />
              </template>
              <template slot="HEAD_date_trans" slot-scope="data">
                <label>{{ data.label }}</label>
              </template>
              <template slot="HEAD_voucher_number" slot-scope="data">
                <label>{{ data.label }}</label>
              </template>
              <template slot="HEAD_nominal" slot-scope="data">
                <label>{{ data.label }}</label>
              </template>
              <template slot="HEAD_mdr" slot-scope="data">
                <label>{{ data.label }}</label>
              </template>
              <template slot="date_trans" slot-scope="data">
                {{ data.value.format('DD/MM/YYYY') }}
              </template>
              <template slot="id" slot-scope="data">
                <b-form-checkbox
                  v-model="form.selectedVouchers"
                  :value="data.value"
                  @change="selectVoucher"
                />
              </template>
              <template slot="custom-foot" slot-scope="data">
                <b-tr>
                  <b-td colspan="5">
                    Total Voucher
                  </b-td>
                </b-tr>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col offset="8" cols="4">
          <b-form-group label-cols="4" label="Total Voucher" class="summary">
            <b-input-group append="Pcs" class="total-voucher">
              <b-form-input dir="rtl" readonly :value="totalVoucher" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col offset="8" cols="4">
          <b-form-group label-cols="4" label="Total Nominal" class="summary">
            <b-form-input dir="rtl" readonly :value="totalNominal | currency" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col offset="8" cols="4">
          <b-form-group label-cols="4" label="Total Paid" class="summary">
            <b-form-input dir="rtl" readonly :value="totalPaid | currency" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-alert v-if="error" show variant="danger">
        {{ error }}
      </b-alert>
      <b-row class="mt-5">
        <div class="update ml-auto mr-auto">
          <b-button
            type="submit"
            variant="primary"
            class="btn-round"
            :disabled="form.selectedVouchers.length === 0 || loading"
            @click="submit"
          >
            Submit
          </b-button><b-spinner v-if="loading" class="ml-1" small label="Loading" />
        </div>
      </b-row>
    </b-form>
    <b-modal id="modal-no-voucher" title="No claimed vouchers" ok-only>
      No claimed vouchers on this outlet
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  props: {
    voucherClaimEndpoint: {
      type: String,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    },
    csrfToken: {
      type: String,
      required: true
    },
    voucherClaims: {
      type: Array,
      required: true
    },
    issuer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        outletId: null,
        selectedVouchers: [],
        selectAll: false
      },
      loading: false,
      error: null,
      vouchers: [],
    }
  },
  computed: {
    selectedVouchers() {
      return (this.vouchers || []).filter(v => this.form.selectedVouchers.indexOf(v.id) !== -1)
    },
    totalVoucher() {
      return this.selectedVouchers.length
    },
    totalNominal() {
      return this.selectedVouchers.reduce((total, v) => total + v.nominal, 0)
    },
    totalDiscount() {
      return this.selectedVouchers.reduce((total, v) =>
        total + v.mdr,
        0
      )
    },
    totalPaid() {
      return this.totalNominal - this.totalDiscount
    },
    outletOptions() {
      return this.voucherClaims.map(claim => ({
        text: `${claim.outlet.name} [${claim.outlet.identity}] (${claim.vouchers.length})`,
        value: claim.outlet.id
      }))
    },
    voucherFields() {
      return [
        { key: 'id' },
        { key: 'date_trans', label: 'Date Trans' },
        { key: 'voucher_number', label: 'Voucher No' },
        { key: 'nominal', label: 'Nominal', class: 'text-align-right' },
        { key: 'mdr', label: 'MDR', class: 'text-align-right' },
      ]
    }
  },
  methods: {
    selectAllVouchers(val) {
      const selectedVouchers = val ? this.vouchers.map(v => v.id) : []
      this.form = {
        ...this.form,
        selectedVouchers
      }
    },
    selectVoucher(val) {
      if (!val) {
        this.form = {
          ...this.form,
          selectAll: false
        }
      } else {
        if (this.vouchers.length-1 === this.selectedVouchers.length) {
          this.form = {
            ...this.form,
            selectAll: true
          }
        }
      }
    },
    async changeOutlet(outletId) {
      this.vouchers = null
      this.form = {
        ...this.form,
        outletId,
        selectAll: false,
        selectedVouchers: []
      }

      const url = this.voucherClaimEndpoint.replace('OUTLET_ID', outletId)
      const res = await axios.get(url, {
        headers: {
          'Accept': 'application/json',
        }
      })
      if (res.data.length === 0) {
        this.vouchers = []
        this.$bvModal.show('modal-no-voucher')
      } else {
        this.vouchers = res.data.reduce((array, claim_trans) => {
          const dataTable = claim_trans.vouchers.map(voucher => ({
            id: voucher.id,
            date_trans: moment(claim_trans.claim_date),
            voucher_number: voucher.unit.voucher_number,
            nominal: voucher.unit.nominal,
            mdr: parseInt(voucher.unit.nominal * (voucher.unit.voucher_theme.disc_rate || 0) / 100)
          }))
          return array.concat(dataTable)
        }, [])
      }
    },
    async submit(evt) {
      if (evt && typeof evt.preventDefault === 'function') {
        evt.preventDefault()
      }

      if (this.loading) {
        return false
      }

      const payload = {
        outlet_id: this.form.outletId,
        vouchers_attributes: this.form.selectedVouchers.map(v => ({ voucher_claim_detail_id: v }))
      }
      try {
        this.error = null
        this.loading = true
        const res = await axios.post(this.endpoint, {
          voucher_paid: payload
        }, {
          headers: {
            'Accept': 'application/json',
            'X-CSRF-Token': this.csrfToken
          }
        })
        this.form = {
          ...this.form,
          selectedVouchers: []
        }
        this.loading = false
        window.location.replace(`${this.endpoint}/${res.data.id}`);
      } catch (err) {
        const { data } = (err && err.response) || {}
        if (data && Array.isArray(data.vouchers)) {
          data.vouchers.forEach(e => {
            const index = this.vouchers.findIndex(v => v.id === e.voucher_claim_id)
            if (index >= 0) {
              const voucher = this.vouchers[index]
              voucher._rowVariant = 'danger'
              this.vouchers.splice(index, 1, voucher)
            }
          })
          this.error = 'Some voucher(s) has already been paid in another transaction'
        } else {
          this.error = err + ''
        }
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.total-voucher .input-group-text { padding: 0 10px !important; border: none; }
.summary { margin-bottom: 5px !important; }
</style>
