<template>
  <div>
    <b-form :action="endpoint"  @submit="submit">
      <b-form-group label="Issuer" label-for="issuer">
        <b-form-input
          id="issuer"
          disabled
          :value="issuer.name"
        />
      </b-form-group>
      <b-row>
        <b-col md="6">
          <b-form-group label="To Company" label-for="company_id">
            <b-form-select
              v-model="form.company_id"
              id="company_id"
              :options="companyOptions"
            >
              <template slot="first">
                <option :value="null" disabled>-- Select Company --</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Voucher Theme" label-for="voucher_theme_id">
            <b-form-select
              v-model="form.voucher_theme_id"
              id="voucher_theme_id"
              :options="voucherThemeOptions"
              @change="changeVoucherTheme"
            >
              <template slot="first">
                <option :value="null" disabled>-- Select Voucher Theme --</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12">
          <b-table
            responsive
            thead-tr-class="text-center"
            :fields="nominalFields"
            :items="!!form.voucher_theme_id ? nominals : []"
          >
            <template slot="total" slot-scope="data">
              <b-form-input
                v-model="data.item.total"
                dir="rtl"
                @blur="getSerial(data.item)"
              />
            </template>
            <template slot="serial" slot-scope="data">
              <div class="text-right">
                <div v-if="data.item.loading">
                  <b-spinner />
                </div>
                <div v-else-if="!!data.item.error" class="text-danger">
                  {{ data.item.error }}
                </div>
                <div v-else>
                  {{ data.value }}
                </div>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12">
          <b-form-group label="Payment Method" label-for="payment_method" class="payment-method">
            <span class="form-check-radio" style="margin-right:15px;">
              <label class="form-check-label">
                <input type="radio" v-model="form.payment_method"  value="cash" />
                <span class="form-check-sign"></span>Cash
              </label>
            </span>
            <span class="form-check-radio">
              <label class="form-check-label">
                <input type="radio" v-model="form.payment_method" inline value="credit" />
                <span class="form-check-sign"></span>Credit
              </label>
            </span>
          </b-form-group>
        </b-col>
      </b-row>
      <b-alert v-if="error" show variant="danger">
        {{ error }}
      </b-alert>
      <b-row>
        <div class="update ml-auto mr-auto">
          <b-button
            type="submit"
            variant="primary"
            class="btn-round"
            :disabled="loading || !canSubmit"
            @click="submit"
          >
            Submit
          </b-button><b-spinner v-if="loading" class="ml-1" small label="Loading" />
        </div>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    endpoint: {
      type: String,
      required: true
    },
    checkAvailabilityEndpoint: {
      type: String,
      required: true
    },
    redirectPrefixPath: {
      type: String,
      required: true
    },
    csrfToken: {
      type: String,
      required: true
    },
    issuer: {
      type: Object,
      required: true
    },
    companies: {
      type: Array,
      required: true
    },
    voucherThemes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      form: {
        company_id: null,
        voucher_theme_id: null,
        payment_method: null
      },
      nominals: this.getInitialNominals(),
      loading: false,
      error: null
    }
  },
  computed: {
    companyOptions() {
      return this.companies.map(company => ({
        value: company.id, text: company.name
      }))
    },
    selectedCompany() {
      return this.companies.find(company => company.id === this.form.company_id)
    },
    voucherThemeOptions() {
      return this.voucherThemes.map(voucherTheme => ({
        value: voucherTheme.id, text: voucherTheme.name
      }))
    },
    nominalFields() {
      return [
        { key: 'nominalText', label: 'Nominal', class: 'text-left' },
        { key: 'total', label: 'Total', class: 'text-right' },
        { key: 'serial', label: 'Serial Voucher', class: 'text-right serial' },
      ]
    },
    canSubmit() {
      return this.form.payment_method !== null &&
        this.nominals.every(nominal =>
          (!nominal.total || nominal.total.trim() === '') ||
          !!nominal.total && parseInt(nominal.total) > 0 && !nominal.error
        ) &&
        this.nominals.filter(nominal => !!nominal.total && parseInt(nominal.total) > 0 && !nominal.error).length > 0
    }
  },
  methods: {
    async submit() {
      if (this.loading) {
        return false
      }

      this.loading = true
      this.error = null

      const payload = {
        ...this.form,
        voucher_units: this.nominals.map(nominal => ({
          nominal: nominal.nominal,
          total: nominal.total
        }))
      }
      try {
        const res = await axios.post(
          this.endpoint,
          { selling_out: payload },
          {
            headers: {
              Accept: 'application/json',
              'X-CSRF-Token': this.csrfToken
            }
          }
        )
        window.location.replace(`${this.redirectPrefixPath}/${res.data.id}`);
      } catch (err) {
        const errData = (err.response.data || {})
        const voucher_units = (errData.voucher_units || [])
        (voucher_units[0] || []).forEach(unit => {
          const index = this.nominals.findIndex(n => n.nominal === unit.nominal)
          const nominal = this.nominals[index]
          nominal.value = null
          nominal.error = this.getErrorMessage(unit.remainings)
          this.nominals.splice(index, 1, nominal)
        })
      }
      this.loading = false
    },
    getInitialNominals() {
      return this.issuer.denominations
        .sort((a, b) => a.nominal - b.nominal)
        .map(denom => ({
          nominalText: denom.nomination,
          nominal: denom.nominal,
          total: null,
          serial: null,
          loading: false,
          error: null
        }))
    },
    changeVoucherTheme() {
      this.nominals = this.getInitialNominals()
    },
    async getSerial(data) {
      data.error   = null
      data.serial  = null

      if (!data.total || data.total.trim() === '' || parseInt(data.total) <= 0) {
        return false
      }

      data.loading = true
      try {
        const res = await axios.get(this.checkAvailabilityEndpoint, {
          params: {
            voucher_theme_id: this.form.voucher_theme_id,
            nominal: data.nominal,
            total: data.total
          },
          headers: {
            Accept: 'application/json'
          }
        })
        if (res.data.error) {
          data.error = this.getErrorMessage(res.data.error.remainings)
        } else {
          data.serial = `${res.data.start} - ${res.data.last}`
        }
      } catch (err) {
        data.error = err + ''
      }
      data.loading = false
    },
    getErrorMessage(remainings = 0) {
      return remainings > 0
        ? `Jumlah tidak cukup, tersisa ${remainings}`
        : 'Voucher tidak tersedia atau sudah habis'
    }
  }
}
</script>

<style>
.serial { width: 50%; }
.payment-method .custom-control-label { color: #000; font-size: inherit; margin-bottom: 0; vertical-align: middle; margin-right: 24px; }
</style>
