<template>
  <div>
    <b-form :action="endpoint"  @submit="submit">
      <b-row>
        <b-col xs="3" md="1">
          <b-form-group label="Outlet" label-for="outletId">
            <b-input id="outletId" readonly :value="outletId" />
          </b-form-group>
        </b-col>
        <b-col xs="3" md="5">
          <b-form-group label="Outlet Name" label-for="outletName">
            <b-input id="outletName" readonly :value="outletName" />
          </b-form-group>
        </b-col>
        <b-col xs="12" md="6">
          <b-form-group label="Amount" label-for="amount">
            <b-input id="amount" readonly :value="amount" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-card title="Detail Voucher">
            <b-table
              responsive
              :fields="detailVoucherFields"
              :items="vouchers"
            >
              <template slot="HEAD_voucher_number" slot-scope="data">
                <label>{{ data.label }}</label>
              </template>
              <template slot="HEAD_nominal" slot-scope="data">
                <label>{{ data.label }}</label>
              </template>
              <template slot="voucher_number" slot-scope="data">
                <div class="input-group mb-3">
                  <b-form-input readonly :value="data.value" />
                </div>
              </template>
              <template slot="nominal" slot-scope="data">
                <div class="input-group mb-3">
                  <b-form-input readonly :value="data.value" />
                  <div class="input-group-append">
                    <b-button variant="danger" @click="remove(data.item.id)">x</b-button>
                  </div>
                </div>
              </template>
            </b-table>
            <b-card-footer>
              <b-button v-b-modal.voucher-checker variant="primary">Add Voucher</b-button>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
      <b-alert v-if="error" show variant="danger">
        {{ error }}
      </b-alert>
      <b-row>
        <div class="update ml-auto mr-auto">
          <b-button
            type="submit"
            variant="primary"
            class="btn-round"
            :disabled="vouchers.length === 0 || loading"
            @click="submit"
          >
            Submit
          </b-button><b-spinner v-if="loading" class="ml-1" small label="Loading" />
        </div>
      </b-row>
    </b-form>
    <b-modal
      size="lg"
      id="voucher-checker"
      title="Add Voucher"
      hide-footer
    >
      <voucher-checker
        transaction
        :claim="claim"
        :show-details="!!selectedVoucher"
        :endpoint="voucherCheckerEndpoint"
        :csrf-token="csrfToken"
        @before-check="beforeCheck"
        @check="check"
      />
      <div v-if="foundVoucher">
        <b-button v-if="!!selectedVoucher" variant="success" block @click="addVoucher">Use Voucher</b-button>
        <b-alert v-else show variant="danger">Voucher is already being used in transaction</b-alert>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import voucherChecker from './voucher-checker'

export default {
  props: {
    endpoint: {
      type: String,
      required: true
    },
    voucherCheckerEndpoint: {
      type: String,
      required: true
    },
    redirectPrefixPath: {
      type: String,
      required: true
    },
    csrfToken: {
      type: String,
      required: true
    },
    outletId: {
      type: String,
      required: true
    },
    outletName: {
      type: String,
      required: true
    },
    payloadName: {
      type: String,
      required: true
    },
    detailPayloadName: {
      type: String,
      required: true
    },
    claim: {
      type: Boolean,
      default: false
    }
  },
  components: {
    voucherChecker
  },
  data() {
    return {
      selectedVoucher: null,
      foundVoucher: false,
      loading: false,
      error: null,
      vouchers: []
      // vouchers: [
      //   { id: 1, voucher_number: 'HKSR-201907000001', nominal: '20.000' },
      //   { id: 10, voucher_number: 'HKSR-201907000010', nominal: '10.000' },
      //   { id: 15, voucher_number: 'HKSR-201907000015', nominal: '50.000' }
      // ]
    }
  },
  computed: {
    amount() {
      return this.vouchers.reduce((total, v) => total + v.nominal, 0)
    },
    detailVoucherFields() {
      return [
        { key: 'voucher_number', label: 'Voucher Number' },
        { key: 'nominal', label: 'Nominal' }
      ]
    }
  },
  methods: {
    async submit(evt) {
      if (evt && typeof evt.preventDefault === 'function') {
        evt.preventDefault()
      }

      if (this.loading) {
        return false
      }

      const payload = {
        outlet_id: this.outletId,
        [`${this.detailPayloadName}`]: this.vouchers.map(v => ({ voucher_unit_id: v.id }))
      }
      try {
        this.error = null
        this.loading = true
        const res = await axios.post(this.endpoint, {
          [`${this.payloadName}`]: payload
        }, {
          headers: {
            'Accept': 'application/json',
            'X-CSRF-Token': this.csrfToken
          }
        })
        this.loading = false
        window.location.replace(`${this.redirectPrefixPath}/${res.data.id}`);
      } catch (err) {
        const { data } = (err && err.response) || {}
        if (data && Array.isArray(data.outlet_transaction_detail)) {
          data.outlet_transaction_detail.forEach(e => {
            const index = this.vouchers.findIndex(v => v.id === e.voucher_unit_id)
            if (index >= 0) {
              const voucher = this.vouchers[index]
              voucher._rowVariant = 'danger'
              this.vouchers.splice(index, 1, voucher)
            }
          })
          this.error = 'Some voucher(s) has already been used in another transaction'
        } else {
          this.error = err + ''
        }
        this.loading = false
      }
    },
    remove(voucherId) {
      const index = this.vouchers.findIndex(v => v.id === voucherId)
      if (index >= 0) {
        this.vouchers.splice(index, 1)
      }
    },
    beforeCheck() {
      this.foundVoucher = false
      this.selectedVoucher = null
    },
    check(voucherUnit) {
      if (!voucherUnit) {
        return false
      }

      this.foundVoucher = true
      const exists = this.vouchers.find(v => v.id === voucherUnit.id)
      if (!exists) {
        this.selectedVoucher = voucherUnit
      }
    },
    addVoucher() {
      this.vouchers.push(this.selectedVoucher)
      this.$bvModal.hide('voucher-checker')
    }
  }
}
</script>
